<template>
  <div class="authoring-wrapper">
    <div class="flex h-full">
      <ProgressBar
        :stepIndex="stepIndex"
        :surveyID="surveyID"
        :stepsType="
          surveyTypeID === 102
            ? 'Reticle Alignment Test'
            : $route.name !== 'pack-test-launch'
            ? 'video'
            : 'pack'
        "
      />
      <section class="wrapper">
        <a-breadcrumb class="breadcrum breadcrum--authoring">
          <a-breadcrumb-item
            ><a href="/">{{ client.ClientName }}</a></a-breadcrumb-item
          >
          <a-breadcrumb-item
            ><a href="/products">Products</a></a-breadcrumb-item
          >
          <a-breadcrumb-item>{{ $route.meta.surveyTitle }}</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="wrapper__inner">
          <div class="stepAuthor" ref="stepAuthorSection">
            <div class="page-title page-title--transparent-svg-color">
              <icon-base
                :width="32"
                :height="33"
                :viewBox1="32"
                :viewBox2="33"
                iconName="rocket"
                iconStroke="#4318FF"
                ><icon-rocket
              /></icon-base>
              <div class="page-title__text">
                <h1>Launch</h1>
                <span>Summary & Confirmation</span>
              </div>
            </div>
            <div class="box-wrapper">
              <div class="info">
                <div>
                  <a-descriptions bordered :column="2">
                    <a-descriptions-item
                      :label="langObj['s6-summary'].summaryProjectName"
                    >
                      {{
                        projectSummary !== null
                          ? projectSummary.projectName
                          : "/"
                      }}
                    </a-descriptions-item>
                    <a-descriptions-item
                      :label="langObj['s6-summary'].summaryCategory"
                    >
                      {{
                        projectSummary !== null && "category" in projectSummary
                          ? projectSummary.category
                          : "/"
                      }}
                    </a-descriptions-item>

                    <a-descriptions-item
                      :label="
                        langObj['s6-summary'].summaryCommunicationObjective
                      "
                      v-if="surveyTypeID === 93"
                    >
                      {{
                        projectSummary !== null
                          ? projectSummary.chosenCommunicationObjective1
                              .CommunicationObjectiveName
                          : "/"
                      }}<span
                        v-if="
                          projectSummary.chosenCommunicationObjective2 !== -1
                        "
                        >,</span
                      >
                      {{
                        projectSummary !== null
                          ? projectSummary.chosenCommunicationObjective2
                              .CommunicationObjectiveName
                          : "/"
                      }}
                    </a-descriptions-item>
                    <a-descriptions-item
                      :label="langObj['s6-summary'].summaryDeepDiveProducts"
                      v-if="surveyTypeID === 93"
                    >
                      <ul v-if="projectSummary !== null" class="mb-0 pl-0">
                        <li
                          v-for="(
                            item, index
                          ) in projectSummary.deepDiveProductsName"
                          :key="index"
                        >
                          {{ item }}
                          <br
                            v-if="
                              index < projectSummary.deepDiveProductsName.length
                            "
                          />
                        </li>
                      </ul>
                    </a-descriptions-item>

                    <a-descriptions-item
                      :label="langObj['s6-summary'].summaryRetailer"
                      v-if="surveyTypeID === 93"
                    >
                      <span
                        v-html="
                          projectSummary !== null
                            ? projectSummary.shelfRetailer
                            : '/'
                        "
                      ></span>
                    </a-descriptions-item>
                    <a-descriptions-item
                      :label="langObj['s6-summary'].summaryAudience"
                      v-else
                    >
                      <span
                        v-html="
                          projectSummary !== null
                            ? projectSummary.audience
                            : '/'
                        "
                      ></span>
                    </a-descriptions-item>

                    <a-descriptions-item
                      :label="langObj['s6-summary'].summaryTotalSampleSize"
                    >
                      {{
                        projectSummary !== null
                          ? projectSummary.sampleSize *
                            (projectSummary.creativesCount || 1)
                          : "/"
                      }}
                    </a-descriptions-item>
                    <a-descriptions-item
                      :label="langObj['s6-summary'].summaryCustomQuestions"
                      :span="surveyTypeID === 97 ? 1 : 2"
                      v-if="surveyTypeID !== 93"
                    >
                      <template v-if="projectSummary !== null">
                        <ol class="mb-0 pl-15">
                          <li
                            v-for="(
                              item, index
                            ) in projectSummary.customQuestions"
                            :key="index"
                          >
                            {{ item }}
                          </li>
                        </ol>
                        <span v-if="projectSummary.customQuestions.length === 0"
                          >/</span
                        >
                      </template>
                    </a-descriptions-item>
                    <a-descriptions-item
                      :label="langObj['s6-summary'].summaryReportingOption"
                      :span="1"
                      v-if="surveyTypeID === 97"
                    >
                      <div class="input-group input-group--small">
                        <a-select
                          class="report-option-dropdown"
                          option-filter-prop="children"
                          show-search
                          placeholder="Please select"
                          :key="Math.random()"
                          @change="setReportOption"
                          :default-value="reportType"
                        >
                          <a-select-option
                            v-for="item in reportingOptions"
                            :key="item"
                            :value="item"
                          >
                            {{ item }}
                          </a-select-option>
                        </a-select>
                      </div>
                    </a-descriptions-item>
                  </a-descriptions>

                  <a-table
                    :data-source="videoItems"
                    :pagination="{ hideOnSinglePage: true }"
                    class="launch-step__video-info"
                    v-if="surveyTypeID !== 92 && surveyTypeID !== 93"
                  >
                    <a-table-column
                      width="45%"
                      key="name"
                      :title="langObj['s6-summary'].summaryVideoName"
                      data-index="name"
                    />
                    <a-table-column
                      key="thumbnail"
                      :title="langObj['s6-summary'].summaryThumbnail"
                      data-index="thumbnail"
                      width="18.33%"
                    >
                      <template slot-scope="text, record">
                        <img :src="record.coverImage" alt="" width="120" />
                      </template>
                    </a-table-column>
                    <a-table-column
                      key="duration"
                      :title="langObj['s6-summary'].summaryDuration"
                      data-index="duration"
                      width="18.33%"
                    />
                    <a-table-column
                      key="sampleSize"
                      :title="langObj['s6-summary'].summarySampleSize"
                      data-index="sampleSize"
                      width="18.33%"
                    />
                  </a-table>

                  <div class="launch-step__total">
                    <div class="agreement">
                      <a-checkbox v-model="privacyCheck">
                        <span
                          v-html="langObj['s6-summary'].summaryPrivacy"
                        ></span>
                      </a-checkbox>
                      <span>
                        <a
                          href="#"
                          v-html="langObj['s6-summary'].summaryPrivacyLink"
                        ></a>
                      </span>
                      <div v-if="privacyCheckValidation">
                        <p
                          class="validation-text font-size-14 mt-10"
                          v-html="langObj['s6-summary'].summaryPrivacyMessage"
                        ></p>
                      </div>
                    </div>
                    <a-descriptions bordered :column="2">
                      <a-descriptions-item
                        :label="langObj['s6-summary'].summaryTotalPrice"
                      >
                        <span class="flex items-center">
                          ${{ formatPrice(price) }}
                          <a-tooltip
                            placement="top"
                            overlayClassName="ant-tooltip--small"
                          >
                            <template slot="title">
                              <span
                                v-html="
                                  langObj['s6-summary'].summaryPriceTooltip
                                "
                              ></span>
                            </template>

                            <a-icon type="question-circle" />
                          </a-tooltip>
                        </span>
                      </a-descriptions-item>
                    </a-descriptions>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <StepNaviBar
            :stepIndex="stepIndex"
            :nextDisable="false"
            @nextHandler="nextStep"
            :isEnd="true"
            :prevDisable="false"
            @prevHandler="prevStep"
            :accountPaymentType="accountPaymentType"
            :loading="loading"
            :prevBtnTooltip="backBtnTooltip"
            :conciergeAssistance="
              $route.name === 'pack-test-launch' ? true : false
            "
            :stepsType="
              surveyTypeID === 102
                ? 'Reticle Alignment Test'
                : $route.name !== 'pack-test-launch'
                ? 'video'
                : 'pack'
            "
          />
          <form :action="apiUrl" method="POST" ref="payLaunchForm">
            <input
              type="hidden"
              name="SurveyId"
              id="SurveyId"
              :value="surveyID"
            />
            <input type="hidden" name="Price" id="Price" :value="price" />
          </form>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import * as wsUtils from "@/utils/wsUtils.js";
import * as jsUtils from "@/utils/jsUtils.js";
import ProgressBar from "@/components/videoAds/ProgressBar.vue";
import StepNaviBar from "@/components/videoAds/StepNaviBar1.vue";
import surveyPriceMixin from "@/mixins/surveyPriceCalculation.js";
import { store } from "@/utils/store.js";
import priceFormating from "@/mixins/priceFormating.js";
import IconBase from "@/components/general/IconBase.vue";
import IconRocket from "@/components/icons/IconRocket.vue";

export default {
  name: "Summary",
  components: {
    ProgressBar,
    StepNaviBar,
    IconBase,
    IconRocket,
  },
  mixins: [surveyPriceMixin, priceFormating],
  data() {
    return {
      privacyCheck: true,
      privacyCheckValidation: false,
      stepIndex: 6,
      surveyID: 0,
      client: {},
      projectSummary: null,
      langObj: {},
      price: null,
      accountPaymentType: null,
      videoDetailsRows: null,
      videoItems: null,
      equalPrices: false,
      loading: false,
      apiUrl: null,
      surveyTypeID: null,
      finalPrice: null,
      breadcrumbTitle: "",
      backBtnTooltip: "",
      reportingOptions: ["Base", "Automated", "Custom"],
      reportType: "Automated",
    };
  },
  watch: {
    privacyCheck(newVal) {
      if (newVal) {
        this.privacyCheckValidation = false;
      }
    },
  },
  methods: {
    prevStep() {
      this.$router.push({
        name: this.$route.meta.launchPrevRoute || "vid-audience",
        params: { id: this.surveyID },
      });
    },
    launchSurvey() {
      const launchPromise = new Promise((resolve) => {
        wsUtils.LaunchSurvey({ SurveyId: this.surveyID }, resolve);
      });

      launchPromise.then((data) => {
        this.$router.push({
          name: "home",
        });
        if (data.success) {
          this.$message.success(data.message, 6);
        } else {
          this.$message.error(data.message, 6);
        }
      });
    },
    setReportOption(e) {
      let currentSummaryData = jsUtils.getSurveySummaryData();
      let currentSurveyPriceData = jsUtils.getSurveyPrice();

      this.reportType = e;
      let data = {
        SurveyID: this.surveyID,
        ReportOptions: e,
      };
      const updateReportOptions = new Promise((resolve) => {
        wsUtils.UpdateReportOptions(data, resolve);
      });

      updateReportOptions.then((data) => {
        let surveySummaryReportOption = {
          ReportOption: e,
        };
        let surveySummaryPrice = {
          ReportType: e,
        };
        if (data.Success) {
          jsUtils.setSurveySummaryData({
            ...currentSummaryData,
            ...surveySummaryReportOption,
          });

          jsUtils.setSurveyPrice({
            ...currentSurveyPriceData,
            ...surveySummaryPrice,
          });
          this.getSurveyTotalPrice();
          this.getFinalSurveyPrice();
        }
      });
    },
    nextStep() {
      let runSurveyMode = jsUtils.getRunSurveyMode();

      if (runSurveyMode === false) {
        this.$notification["warning"]({
          message: `Oops`,
          description:
            "You can't 'Pay & Launch' study because you haven't filled in all the information for the Pack test.",
          placement: "bottomLeft",
          duration: 10,
        });
      } else {
        if (this.equalPrices) {
          if (this.privacyCheck) {
            this.privacyCheckValidation = false;
            jsUtils.setSurveysApiTrigger(true);
            if (this.accountPaymentType === "Invoiced") {
              this.launchSurvey();
            } else {
              this.loading = true;
              this.$refs.payLaunchForm.submit();
            }
          } else {
            this.privacyCheckValidation = true;
            const stepAuthorSection = this.$refs.stepAuthorSection;
            stepAuthorSection.scrollTop = stepAuthorSection.scrollHeight;
          }
        } else {
          this.$router.push({
            name: "global-error-page",
            params: {
              id: "1321-12423",
              surveyId: this.surveyID,
              description: `Survey ID: ${this.surveyID}, Cliend ID: ${this.client.ClientId}, Client Name: ${this.client.ClientName}, Total Price - Front end calculation: ${this.price}, Final Price - Back end calculation: ${this.finalPrice}`,
            },
          });
        }
      }
    },
    setTotalPrice(price) {
      this.price = store.surveyTotalPrice;

      let surveySummary = {
        price: price,
      };
      let currentSummaryData = jsUtils.getSurveySummaryData();
      jsUtils.setSurveySummaryData({ ...currentSummaryData, ...surveySummary });

      this.getFinalSurveyPrice();
    },
    getFinalSurveyPrice() {
      const GetFinalSurveyPrice = new Promise((resolve) => {
        wsUtils.GetFinalSurveyPrice(this.$route.params.id, resolve);
      });
      GetFinalSurveyPrice.then((data) => {
        this.finalPrice = data;
        if (this.price === data) {
          this.equalPrices = true;
        } else {
          this.equalPrices = false;
        }
      });
    },
    getBackButtonTooltip(routeName) {
      let backButtonTooltip;
      switch (routeName) {
        case "comms-launch":
          backButtonTooltip = "Back to Key Metrics";
          break;
        case "vid-ua-launch":
          backButtonTooltip = "Back to Customize";
          break;
        default:
          backButtonTooltip = "Back to Audience";
          break;
      }
      return backButtonTooltip;
    },
  },
  mounted() {
    this.client = jsUtils.getCurClient();
  },
  activated() {
    this.surveyTypeID = jsUtils.getSurveyTypeId();
    this.backBtnTooltip = this.getBackButtonTooltip(this.$route.name);
    this.surveyID = this.$route.params.id;
    if (window.location.hostname === "app.hotspexlabs.com") {
      this.apiUrl =
        "https://admin.hotspexlabs.com/Cart/create-checkout-session";
    } else {
      this.apiUrl =
        "https://staging-admin.hotspexstudio.com/Cart/create-checkout-session";
    }

    this.projectSummary = jsUtils.getSurveySummaryData();
    this.reportType = this.projectSummary.ReportOption;
    this.accountPaymentType = jsUtils.getAccountPaymentType();
    this.videoItems =
      this.projectSummary.creatives !== undefined
        ? this.projectSummary.creatives.map((item, index) => {
            return {
              ...item,
              sampleSize: this.projectSummary.sampleSize,
              key: index,
            };
          })
        : null;

    this.getSurveyTotalPrice();
    this.setTotalPrice();
  },
  created() {
    this.langObj = jsUtils.getLangObj(
      "videoAuthorLang",
      "/tooltip/videoAuthor.json"
    );
    this.breadcrumbTitle =
      this.$route.name !== "pack-test-launch" ? "Video Ad" : "Pack";
  },
};
</script>

<style lang="scss" scoped>
.stepAuthor {
  display: block;
  width: 100%;
  margin: auto;
  max-width: 1010px;
  ::v-deep .ant-descriptions-bordered {
    &.total-table {
      text-align: center;
      .ant-descriptions-item-label {
        padding: 18px 12px;
        width: 154px;
      }
      .ant-descriptions-item-content {
        min-width: 114px;
      }
    }
    .ant-descriptions-item-label {
      padding: 9px 12px;
      width: 270px;
      max-width: 270px;
      font-size: 12px;
      font-weight: 600;
      background: #f0f0f0;
      border-color: #b9b9b9;
    }
  }
  ::v-deep .ant-descriptions-bordered .ant-descriptions-view {
    border-color: #b9b9b9;
  }
  ::v-deep .ant-descriptions-bordered .ant-descriptions-item-content {
    min-width: 140px;
    color: #1c1c1c;
  }
  ::v-deep .ant-descriptions-item-content {
    width: 265px;
    padding: 9px 12px;
    font-size: 12px;
    border-color: #b9b9b9;
  }
  ::v-deep .ant-descriptions-row {
    border-color: #b9b9b9;
  }
}

.agreement {
  padding-right: 20px;
  padding: 16px 12px 13px;
  border-radius: 5px;
  background: #f4f7fe;
  flex: 1;
  h4 {
    font-size: 16px;
    font-weight: bold;
  }

  a {
    color: var(--red);
    text-decoration: underline;
    margin-left: -5px;
    font-weight: 500;
    letter-spacing: -0.32px;
  }

  label {
    font-size: 15px;
    font-weight: 500;
    color: rgba(28, 28, 28, 0.9);
    letter-spacing: -0.32px;
  }

  ::v-deep .ant-checkbox-inner {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border-color: var(--red);
    background: var(--red);
    &:after {
      width: 9px;
      height: 18px;
      top: 45%;
    }
  }

  ::v-deep .ant-checkbox-checked::after {
    border-radius: 50%;
    border-color: var(--red);
  }
}
</style>

<style lang="scss">
.ant-descriptions-item-label {
  font-weight: 500;
  background-color: #f6fcff;
  color: var(--hsgrey) !important;
}

.launch-step {
  padding-bottom: 80px !important;
  &__video-info {
    margin: 25px 0 0;
    border-width: 1px 1px 0;
    border-style: solid;
    border-color: #b9b9b9;
    border-radius: 4px;

    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      border-right: 1px solid #b9b9b9;
      padding: 16px 24px;
      border-color: #b9b9b9;
      font-size: 12px;
      &:last-child {
        border-right: 0;
      }
    }

    .ant-table-thead > tr > th {
      font-size: 12px;
      padding: 9px 12px;
      font-weight: 600;
      background: #f0f0f0;
      border-color: #b9b9b9;
    }
    .ant-table-tbody {
      tr {
        &:hover {
          td {
            background: #fff !important;
          }
        }
      }
    }
  }
  &__total {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    gap: 24px;
    .ant-descriptions {
      max-width: 260px;
      width: 100%;
      .anticon {
        margin-left: 8px;
        display: block;
        cursor: pointer;
        svg {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}

.report-option-dropdown {
  width: 152px;
}
</style>
