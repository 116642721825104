var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"authoring-wrapper"},[_c('div',{staticClass:"authoring"},[_c('div',{staticClass:"flex"},[_c('ProgressBar',{attrs:{"stepIndex":_vm.progressStepIndex,"surveyID":_vm.surveyID,"stepsType":_vm.progressStepsType}}),_c('section',{staticClass:"wrapper"},[_c('a-breadcrumb',{staticClass:"breadcrum breadcrum--authoring"},[(_vm.user !== null && _vm.user.SkinIdentifier !== 'pg')?[_c('a-breadcrumb-item',[_c('a',{attrs:{"href":"/"}},[_vm._v(_vm._s(_vm.client.ClientName))])]),_c('a-breadcrumb-item',[_c('a',{attrs:{"href":"/products"}},[_vm._v("Products")])]),_c('a-breadcrumb-item',[_vm._v(_vm._s(_vm.breadcrumbTitle))])]:_vm._e()],2),_c('div',{staticClass:"wrapper__inner"},[_c('div',{ref:"stepAuthorSection",staticClass:"stepAuthor"},[_c('div',{staticClass:"page-title page-title--transparent-svg-color"},[_c('icon-base',{attrs:{"width":32,"height":33,"viewBox1":32,"viewBox2":33,"iconName":"rocket","iconStroke":"#4318FF"}},[_c('icon-rocket')],1),_vm._m(0)],1),_c('div',{staticClass:"box-wrapper"},[(_vm.surveyTypeID !== 108)?_c('a-row',{staticClass:"flex mb-25",attrs:{"gutter":16}},[_c('a-col',{staticStyle:{"width":"52%"},attrs:{"span":12}},[_c('ActiveShelf',{attrs:{"activeShelf":_vm.projectSummary !== null
                        ? _vm.projectSummary.activeShelf
                        : null,"showEdit":false,"showRetailer":true}})],1),_c('a-col',{staticStyle:{"width":"48%"},attrs:{"span":12}},[_c('SelectedProducts',{attrs:{"selectedProducts":_vm.projectSummary !== null
                        ? _vm.projectSummary.chosenProducts
                        : null,"showEdit":false}})],1)],1):_c('a-row',{staticClass:"flex mb-25",attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24}},[_c('SelectedBrands',{attrs:{"selectedBrands":_vm.projectSummary ? _vm.projectSummary.mapMakerBrandData : null}})],1)],1),_c('div',[_c('a-descriptions',{attrs:{"bordered":"","column":2}},[_c('a-descriptions-item',{attrs:{"label":_vm.langObj['s6-summary'].summaryProjectName}},[_vm._v(" "+_vm._s(_vm.projectSummary ? _vm.projectSummary.projectName : "/")+" ")]),_c('a-descriptions-item',{attrs:{"label":_vm.langObj['s6-summary'].summaryCountry}},[_c('span',{domProps:{"innerHTML":_vm._s(
                        _vm.projectSummary !== null ? _vm.projectSummary.country : '/'
                      )}})]),_c('a-descriptions-item',{attrs:{"label":_vm.langObj['s6-summary'].summaryCategory}},[_vm._v(" "+_vm._s(_vm.projectSummary !== null && "category" in _vm.projectSummary ? _vm.projectSummary.category : "/")+" ")]),(_vm.surveyTypeID !== 108)?_c('a-descriptions-item',{attrs:{"label":_vm.langObj['s6-summary'].summaryBrand}},[_c('span',{domProps:{"innerHTML":_vm._s(
                        _vm.projectSummary !== null ? _vm.projectSummary.brand : '/'
                      )}})]):_vm._e(),(_vm.surveyTypeID !== 108)?_c('a-descriptions-item',{attrs:{"label":_vm.langObj['s6-summary'].summaryLegsName}},[(_vm.projectSummary !== null)?_c('ul',{staticClass:"mb-0 pl-0"},_vm._l((_vm.projectSummary.legsName),function(item,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(item)+" "),(index < _vm.projectSummary.legsName.length)?_c('br'):_vm._e()])}),0):_vm._e()]):_vm._e(),(_vm.surveyTypeID !== 108)?_c('a-descriptions-item',{attrs:{"label":_vm.projectSummary &&
                      _vm.projectSummary.studyType &&
                      _vm.projectSummary.studyType === 'SBD'
                        ? _vm.langObj['s6-summary'].summaryFindabilityProducts
                        : _vm.langObj['s6-summary'].summaryDeepDiveProducts}},[(_vm.projectSummary !== null)?_c('ul',{staticClass:"mb-0 pl-0"},_vm._l((_vm.projectSummary.deepDiveProductsName),function(item,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(item)),(
                            index + 1 <
                            _vm.projectSummary.deepDiveProductsName.length
                          )?_c('span',[_vm._v(",")]):_vm._e(),(
                            index < _vm.projectSummary.deepDiveProductsName.length
                          )?_c('br'):_vm._e()])}),0):_vm._e()]):_vm._e(),(_vm.surveyTypeID !== 108)?_c('a-descriptions-item',{attrs:{"label":_vm.langObj['s6-summary'].summaryCommunicationObjective}},[_vm._v(" "+_vm._s(_vm.projectSummary !== null ? _vm.projectSummary.chosenCommunicationObjective1 .CommunicationObjectiveName : "/")),(
                        _vm.projectSummary !== null &&
                        _vm.projectSummary.chosenCommunicationObjective2 !== -1
                      )?_c('span',[_vm._v(",")]):_vm._e(),_vm._v(" "+_vm._s(_vm.projectSummary !== null ? _vm.projectSummary.chosenCommunicationObjective2 .CommunicationObjectiveName : "/")+" ")]):_vm._e(),_c('a-descriptions-item',{attrs:{"label":_vm.sampleLabel}},[_c('div',{staticClass:"flex items-center sample-size"},[_c('div',{class:{ disabled: !_vm.changeSampleSize },on:{"click":function($event){_vm.openSampleSize = !_vm.openSampleSize},"focusout":function($event){_vm.openSampleSize = false}}},[_c('a-select',{key:Math.random(),staticClass:"sample-size__select",class:{
                            'sample-size__select--disabled':
                              !_vm.changeSampleSize,
                          },attrs:{"default-value":_vm.sampleSize,"disabled":!_vm.changeSampleSize,"open":_vm.openSampleSize,"dropdownMatchSelectWidth":false},on:{"change":_vm.selectSampleSize}},_vm._l(([
                              75, 100, 150, 200, 250, 300 ]),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item}},[_vm._v(" "+_vm._s(item)+" ")])}),1)],1),_c('div',[(!_vm.changeSampleSize)?_c('a-button',{staticClass:"btn-change-sample",attrs:{"type":"primary"},on:{"click":function($event){(_vm.openSampleSize = true), (_vm.changeSampleSize = true)}}},[_vm._v(" Change")]):_c('a-button',{staticClass:"btn-change-sample",attrs:{"type":"primary"},on:{"click":function($event){(_vm.openSampleSize = false),
                              (_vm.changeSampleSize = false)}}},[_vm._v("Close")])],1)])]),(_vm.surveyTypeID !== 108)?_c('a-descriptions-item',{attrs:{"label":_vm.langObj['s6-summary'].summaryCostCenter}},[_vm._v(" "+_vm._s(_vm.editableSurvey !== null ? _vm.editableSurvey.SurveyConfig.CostCenter !== "" ? _vm.editableSurvey.SurveyConfig.CostCenter : "/" : "/")+" ")]):_vm._e(),_c('a-descriptions-item',{attrs:{"label":_vm.langObj['s6-summary'].summaryEstimateCompletionDate}},[_vm._v(" "+_vm._s(_vm.estimateCompletionDate)+" ")]),(
                      _vm.user &&
                      _vm.user.SkinIdentifier !== 'pg' &&
                      _vm.surveyTypeID !== 108
                    )?_c('a-descriptions-item',{attrs:{"label":_vm.langObj['s6-summary'].summaryReportingOption}},[_c('div',{staticClass:"input-group input-group--small"},[_c('a-select',{key:Math.random(),staticClass:"report-option-dropdown",attrs:{"option-filter-prop":"children","show-search":"","placeholder":"Please select","default-value":_vm.reportType},on:{"change":_vm.setReportOption}},_vm._l((_vm.reportingOptions),function(item){return _c('a-select-option',{key:item,attrs:{"value":item}},[_vm._v(" "+_vm._s(item)+" ")])}),1)],1)]):_vm._e(),(
                      ((_vm.projectSummary && !_vm.projectSummary.studyType) ||
                        (_vm.projectSummary &&
                          _vm.projectSummary.studyType &&
                          _vm.projectSummary.studyType !== 'SBD')) &&
                      _vm.surveyTypeID !== 108
                    )?_c('a-descriptions-item',{attrs:{"label":_vm.langObj['s6-summary'].summaryImplicitComponent}},[_c('a-checkbox',{on:{"change":_vm.setImplicitComponent},model:{value:(_vm.implicitComponent),callback:function ($$v) {_vm.implicitComponent=$$v},expression:"implicitComponent"}},[(!_vm.implicitComponent)?_c('span',[_vm._v("Enable")]):_c('span',[_vm._v("Disable")])])],1):_vm._e(),(
                      _vm.user &&
                      _vm.user.SkinIdentifier !== 'pg' &&
                      _vm.surveyType !== 'packtestextend'
                    )?_c('a-descriptions-item',{attrs:{"label":_vm.langObj['s6-summary'].summaryCustomQuestions}},[(_vm.projectSummary !== null)?[_c('ol',{staticClass:"mb-0 pl-15"},_vm._l((_vm.projectSummary.customQuestions),function(item,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(item)+" ")])}),0),(_vm.projectSummary.customQuestions.length === 0)?_c('span',[_vm._v("/")]):_vm._e()]:_vm._e()],2):_vm._e()],1),_c('div',{staticClass:"launch-step__total"},[_c('div',{staticClass:"agreement"},[_c('a-checkbox',{model:{value:(_vm.privacyCheck),callback:function ($$v) {_vm.privacyCheck=$$v},expression:"privacyCheck"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.langObj['s6-summary'].summaryPrivacy)}})]),_c('span',[_c('a',{staticClass:"terms",attrs:{"href":"#"},domProps:{"innerHTML":_vm._s(_vm.langObj['s6-summary'].summaryPrivacyLink)}})]),(_vm.privacyCheckValidation)?_c('div',[_c('p',{staticClass:"validation-text font-size-14 mt-10",domProps:{"innerHTML":_vm._s(_vm.langObj['s6-summary'].summaryPrivacyMessage)}})]):_vm._e()],1),_c('div',[_c('a-descriptions',{staticClass:"total-table",attrs:{"bordered":"","column":1}},[_c('a-descriptions-item',{attrs:{"label":"Estimated price:"}},[_c('span',{staticClass:"flex items-center justify-end"},[_vm._v(" $"+_vm._s(_vm.formatPrice(_vm.getPackTestPrice))+" "),(_vm.surveyTypeID !== 108)?_c('a-tooltip',{attrs:{"placement":"top","overlayClassName":"ant-tooltip--small ant-tooltip--step-nav"}},[_c('template',{slot:"title"},[_c('span',{domProps:{"innerHTML":_vm._s(
                                  _vm.langObj['s6-summary'].summarySampleSizeInfo
                                )}})]),_c('a-icon',{attrs:{"type":"question-circle"}})],2):_vm._e()],1)]),(_vm.surveyTypeID !== 108)?_c('a-descriptions-item',{attrs:{"label":"Estimated OOP:"}},[_c('span',{staticClass:"flex items-center justify-end"},[_vm._v(" $"+_vm._s(_vm.formatPrice(_vm.costDetails.estimatedCost))+" "),_c('a-tooltip',{attrs:{"placement":"top","overlayClassName":"ant-tooltip--small ant-tooltip--step-nav"}},[_c('template',{slot:"title"},[_c('span',{staticClass:"d-block"},[_vm._v("Cost for sample: $"+_vm._s(_vm.formatPrice(_vm.costDetails.costForSample)))]),_c('span',{staticClass:"d-block"},[_vm._v("Maintenance cost: $"+_vm._s(_vm.formatPrice(_vm.costDetails.maintenanceCost)))]),_c('span',{staticClass:"d-block"},[_vm._v("Data processing: $"+_vm._s(_vm.formatPrice(_vm.costDetails.dataProcessing)))])]),_c('a-icon',{attrs:{"type":"question-circle"}})],2)],1)]):_vm._e(),_c('a-descriptions-item',{attrs:{"label":"Enter PO:"}},[_c('div',{staticClass:"input-group input-group--small"},[_c('a-input',{model:{value:(_vm.po),callback:function ($$v) {_vm.po=$$v},expression:"po"}})],1)])],1)],1)])],1)],1)]),_c('StepNaviBar',{attrs:{"stepIndex":_vm.stepIndex,"nextDisable":false,"prevDisable":_vm.getQuotaConfigLoading && _vm.isPackSbdTestPresent,"isEnd":true,"accountPaymentType":_vm.accountPaymentType,"loading":_vm.loading ||
              _vm.getPriceLoader ||
              _vm.finalPriceCalculationLoader ||
              _vm.getCheckingFeasibilityLoader,"prevBtnTooltip":"Back to Brand Strategy","conciergeAssistance":_vm.$route.name === 'pack-test-launch' ? true : false,"stepsType":_vm.$route.name === 'pack-test-launch' ? 'pack' : 'map-maker-test'},on:{"nextHandler":_vm.nextStep,"prevHandler":_vm.prevStep}})],1)],1)],1),_c('form',{ref:"payLaunchForm",attrs:{"action":_vm.apiUrl,"method":"POST"}},[_c('input',{attrs:{"type":"hidden","name":"SurveyId","id":"SurveyId"},domProps:{"value":_vm.surveyID}}),_c('input',{attrs:{"type":"hidden","name":"Price","id":"Price"},domProps:{"value":_vm.finalPrice}})])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-title__text"},[_c('h1',[_vm._v("Launch")]),_c('span',[_vm._v("Summary & Confirmation")])])}]

export { render, staticRenderFns }